import { Message } from 'element-ui';
import api from "../http/api";
import { getMacMethod,getDays,nowDataMethod } from "../utils";

let process, os, iconv, path, fs, request, win, gui, http, log4js, nodeProcess;
let isMax = true;
let token;
if (top.window.require) {
    gui = window.require('nw.gui');
    win = window.nw.Window.get();
    request = window.require('request');
    nodeProcess = window.require('process')
    fs = window.require('fs');
    path = window.require('path');
    win.show(); //解决窗口闪烁BUG
    process = window.nw.require('child_process');
    os = require('os');
    iconv = window.require("iconv-lite");
    http = window.require('http');
    log4js = window.require('log4js');
    win.on('close', function () {
        this.hide();
        clearCache(() => {
           api.logout({
                aunique_id: getMacMethod()
            }).then(res => {
               this.close(true);
            })
        })
    });
    win.evalNWBin(null, 'source.bin')  //Mac加密
}

function clearCache(callback) {
    try {
         //获取token
        token = localStorage.getItem('token')
        window.nw.App.clearCache();
        window.chrome.browsingData.remove({
            since: 0
        }, {
            appcache: true,
            cache: true,
            cookies: true,
            downloads: true,
            fileSystems: true,
            formData: true,
            history: true,
            indexedDB: true,
            localStorage: true,
            pluginData: true,
            passwords: true,
            serverBoundCertificates: true,
            serviceWorkers: true,
            webSQL: true
        }, function () {
               //存token
            localStorage.setItem('token', token)
            callback && callback(true)
            //console.log('clear cache success!!!')
        });
    } catch (e) {
        console.log('报错e', e)
    }
}
function runCmd(cmd, cb) {
    process.exec(cmd, { encoding: 'buffer' }, (error, stdout, stderr) => {
        if (!error) {
            // 成功
            //console.log('ok', stdout);
            if (cb) {
                cb(stdout);
            }
        } else {
            // 失败
            console.log('err', stderr);
            if (cb) {
                cb(stderr)
            }
        }
    });
}
//获取Mac地址
export function getMac() {
    return new Promise((reslove, reject) => {
        runCmd('ipconfig /all', function (data) {
            console.log('data?', data)
            if (data) {
                data = iconv.decode(data, 'cp936');
            }
            //console.log('mac', data.toString().replace(/[\r|\n|\s+]/g, ''));
            let id = data.toString();
            //04-D9-F5-C8-F4-99
            let macs = id.match(/([0-9A-Fa-f]{2}-){5}([0-9A-Fa-f]{2})/);
            let strs = "";
            if (macs) {
                macs.forEach(item => {
                    if (item.match(/([0-9A-Fa-f]{2}-){5}([0-9A-Fa-f]{2})/)) {
                        console.log(item);
                        strs += item + ',';
                    }
                });
            }
            console.log(strs)
            reslove(strs)
        });
    })
}
//获取软件安装目录
export function getPath() {
    console.log('process.execPath', nodeProcess.execPath)
    return path.dirname(nodeProcess.execPath)
}
export function getOs() {
    return os.hostname();
}
//处理同名
function sameFileMethod(str, filepath, n) {
    const strArr = str.split('.')
    let joinStr = strArr[0] + (n ? `(${n})` : '') + '.' + strArr[1];
    const res = fs.existsSync(path.join(filepath, joinStr));
    if (res) {
        return sameFileMethod(str, filepath, ++n);
    } else {
        return joinStr
    }
}
//判断下载地址是否存在
export function isDownAddress(dirPath) {
    if (!fs.existsSync(dirPath)) {
        try {
            fs.mkdirSync(dirPath)
            return true;
        } catch (error) {
            return false
        }

    }
    return true;
}
// 下载方法二
export function downFileExt(url, dirPath, fileName, isSame, isDownNum = 0) {
    console.log('下载url', url, dirPath, fileName)
   
    //console.log('进来时间：', Math.round(new Date() / 1000))
    return new Promise(function (resolve, reject) {
        let timeout = 5000; //过期时间
        console.log('url', url)
        var req = http.get(url, function (res) {
            console.log('res', res)
            if (res.statusCode == 200) {
                let imgData = "";
                res.setEncoding("binary"); //一定要设置response的编码为binary否则会下载下来的图片打不开
                //let loaded = 0; //下载进度
                res.on("data", function (chunk) {
                    console.log('data进这里了？')
                    // loaded += chunk.length;
                    imgData += chunk;
                    // let loading = Math.floor(loaded / res.headers['content-length'] * 100);
                    // if (cb) {
                    //     cb(loading);//下载进度条
                    // }

                });
                res.on("end", async function () {
                    console.log('end', fs.existsSync(dirPath))
                    if (!fs.existsSync(dirPath)) {
                        console.log('111dir')
                        try {
                            await fs.mkdirSync(dirPath)
                        } catch (error) {
                            reject({ code: 203 })
                            //console.log('创建八错',error)
                        }

                    }
                    let savePath = path.join(dirPath, fileName);
                    if (fs.existsSync(savePath) && !isSame) {
                        savePath = path.join(dirPath, sameFileMethod(fileName, dirPath, 0))
                    }
                    //console.log('imgData', savePath)
                    fs.writeFile(savePath, imgData, "binary", function (err) {
                        if (err) {
                            console.log('err,', err)
                            resolve({ code: 201 });
                            return;
                        }
                        resolve({ code: 200 });
                        //console.log("保存成功");
                    });
                });
                res.on("error", function (err) {
                    console.log("请求失败", err);
                    resolve({ code: 201 });
                });
            } else {
                console.log('网络异常或者图片不存在')
                resolve({ code: 201 });
                // reject('网络异常或者图片不存在');
            }
        });
        req.on('error', function (err) {
            console.log("请求失败2", err, err.message);
            resolve({ code: 201 });
            //resolve(downFileExt(url, dirPath, fileName, true))
        });
        req.setTimeout(timeout, function () {
            console.log('请求超时')
            if (isDownNum >= 3) {
                console.log('超过2次时,直接报错')
                resolve({ code: 202 });
                return;
            }
            //超时的使用递归再请求
            req.abort();
            req.end();
            resolve(downFileExt(url, dirPath, fileName, true, ++isDownNum))
            //resolve({ code: 202 });
        });
        req.end();
    });
}

export const objectMethod=(data)=>{
   return data
}
//添加consol日志
export function addConsoleTxt(content){
    //console.log()
    appendText(path.join(getPath(),'/log'),getDays(1)[0]+'.txt' , content)
}
//写入txt
export function appendText(filePath, fileName, content) {
    console.log('appendText', filePath, fileName, content)
    return new Promise(async function (resolve, reject) {
        let savePath = path.join(filePath, fileName);
        console.log('savePath', savePath)
        if (!fs.existsSync(filePath)) {
            // console.log('111dir')
            try {
                await fs.mkdirSync(filePath)
            } catch (error) {
                reject({ code: 203 })
                //console.log('创建八错',error)
            }
        }
        //!fs.existsSync(filePath) && fs.mkdirSync(filePath)
        fs.appendFile(savePath, content + '\r\n', (err, data) => {
            console.log('写入txt', data, err);
            if (err) {
                resolve({ code: 201 });
                // resolve(false)
            } else {
                resolve({ code: 200 });
                // resolve(true);
            }
        });
    })
}
//下载
export function downFile(url, dirPath, fileName) {
    return new Promise(function (resolve, reject) {
        try {
            request.get({
                url,
                timeout: 500
            }, function (error, response, body) {
                console.log('body', response, error)
                let filePath = fileName;
                if (!error && response.statusCode == 200) {
                    !fs.existsSync(dirPath) && fs.mkdirSync(dirPath)
                    let fsName = fs.existsSync(path.join(dirPath, fileName))
                    if (fsName) {
                        filePath = sameFileMethod(fileName, dirPath, 0)
                    }
                    //console.log('咋子回事', filePath)
                    let stream = fs.createWriteStream(path.join(dirPath, filePath));
                    // console.log('stream', stream)
                    request({
                        url,
                        timeout: 500
                    }).pipe(stream).on("finish", function (err) {
                        console.log('err22', err)
                        resolve({ code: 200 });
                    }).on('error', function (err) {
                        resolve({ code: 201 });
                        console.log('err11', err)
                    });


                } else {
                    console.log('错误进这里了?//')
                    resolve(downFile(url, dirPath, fileName));
                    // if (error) {
                    //     Message({
                    //         message: `下载失败~`,
                    //         type: 'error'
                    //     });
                    //     // console.log('下载不下来？？')
                    //     reject(error);
                    // } else {
                    //     Message({
                    //         message: `下载失败，请检查图片地址是否正确~`,
                    //         type: 'error'
                    //     });
                    //     reject(new Error("下载失败，返回状态码不是200，状态码：" + response.statusCode));
                    // }
                }
            });
        } catch (err) {
            resolve({ code: 201 });
            console.log('我下载为啥子报错', err)
        }
    })
}
//打开本地文件夹
export function openPath(path) {
    process.spawn('explorer.exe', [path]);
}
//#region 关闭窗口
export function closeWin() {
    // win.hide();
    win.close(true);
    //gui.App.quit()
}
//#endregion

//#region窗体大小改变后的还原
export function CenterWin() {
    win.setPosition('center');
    win.resizeTo(1280, 800);
    setTimeout(() => {
        win.setPosition("center");
    }, 50)
    isMax = false;
}
//#endregion
//#region 最小化窗口到任务栏
export function minWin() {
    win.minimize();
    console.log('最小？')
}
//#endregion

//#region窗体最大化
export function maxWin() {
    isMax = true;
    win.maximize();
}
//#endregion

//#region窗体隐藏到系统托盘
export function hide() {
    win.focus();
    win.hide();
}
//最大
export function restore() {
    if (isMax) {
        isMax = false;
    }
    win.restore();
}
export function hideWin() {
    win.hide();
}
export function showWin() {
    win.show();
}

//重设窗体尺寸
export function resizeTo(width, height) {
    win.hide();
    win.resizeTo(width, height);
    // setCenter();
    win.show();
    setTimeout(() => {
        setCenter();
    }, 50);

}
//使窗体居中
export function setCenter() {
    win.setPosition("center");
}
// 打开新窗口
export function openNew(url) {
    // tray.remove();
    // tray = null;
    window.require('nw.gui').Window.open(url, {
        new_instance: false,
        focus: true,
        position: 'center',
        width: 1280,
        height: 800,
        frame: false,
        resizable: false,//为false的话，最大化窗口有重叠
        fullscreen: false,
        transparent: false,//为true的时候最大化是全屏的，会挡住任务栏（20180108）
        show: false //解决窗口闪烁BUG
    }, function (mainwin) {
        win.hide();
        win.close(true);
    });
}

//错误日志
export function logMethod(err, info) {
    log4js.configure({
        replaceConsole: true,
        appenders: {
            console: {
                type: 'console'
            },
            file: { type: 'file', filename: 'console.log' }
            // cheese: {
            //     // 设置类型为 dateFile
            //     type: 'dateFile',
            //     // 配置文件名为 myLog.log
            //     filename: 'logs/log.log',
            //     // 指定编码格式为 utf-8
            //     encoding: 'utf-8',
            //     // 配置 layout，此处使用自定义模式 pattern
            //     layout: {
            //         type: "pattern",
            //         // 配置模式，下面会有介绍
            //         //pattern: '{"date":"%d","level":"%p","category":"%c","host":"%h","pid":"%z","data":\'%m\'}'
            //         pattern: '[%d] [%c] - %m'
            //     },
            //     // 日志文件按日期（天）切割
            //     pattern: "-yyyy-MM-dd",
            //     // 回滚旧的日志文件时，保证以 .log 结尾 （只有在 alwaysIncludePattern 为 false 生效）
            //     keepFileExt: true,
            //     // 输出的日志文件名是都始终包含 pattern 日期结尾
            //     alwaysIncludePattern: true,
            // },
        },
        categories: {
            // 设置默认的 categories
            default: { appenders: ['console', 'file'], level: 'debug' },
        }
    });
    let logger = log4js.getLogger()
    logger.error(err);
    logger.info(info)
}

