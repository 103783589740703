import Vue from 'vue'
import App from './App.vue'
import router from '../router'
import store from '../store/index'
import {resizeTo,showWin} from '../utils/win'
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
//使用UmyUi替代elementUI table
import UmyUi from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css';// 引入样式
// 按需加载elementUi
import {Button,Table,TableColumn,Select,Option,Empty,Pagination, Input,Checkbox,CheckboxGroup,Carousel,CarouselItem,Loading,Dialog}  from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
Vue.use(Button)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Empty)
Vue.use(Pagination)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Dialog)
// Vue.use(Upload)
Vue.use(Loading.directive)
Vue.use(UmyUi)
//路由拦截
// router.beforeEach(async(to,from,next)=>{
// // console.log('to',to,from,next)
// if(to.fullPath == "/"){
//     // let getMac=await getMacMethod()
//     // console.log('111getMac',getMac,localStorage.getItem('mac'))
//     if(localStorage.getItem('token')){
//       top.window.require&&resizeTo(1280,800)
//      setTimeout(()=>{
//       next({
//         name:'index',
//       });
//      },0)
      
//     }else {
//       next({
//         path:'/login',
//       })
//       top.window.require&&resizeTo(800,600)
//     }
//   }
//   next()
// })

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
