import axios from 'axios'
import store from '../store/index'
import router from '../router'
import {resizeTo,addConsoleTxt} from '../utils/win'
import {getMacMethod} from '../utils/index'
// let baseUrl='/web'
//let baseUrl='http://122.114.19.228:7088'
//let baseUrl='http://y36903w635.qicp.vip' //娇娇测试地址
let baseUrl='//ss.maibacc.cn'
//let baseUrl='http://localhost:7088'

let config={
    baseURL:baseUrl,
    timeout:60000
}

const _axios =axios.create(config)
_axios.interceptors.request.use(async (config)=>{
  let token=localStorage.getItem('token')
  token&&(config.headers.Authorization = token)
 // console.log('getMacMethod',await getMacMethod())
  getMacMethod()&&(config.headers['sn_id']=await getMacMethod())
    return config
},
err=>Promise.reject(err)
)
_axios.interceptors.response.use(res=>{
  //console.log('获取到的数据',res)
  if(res.status==200){
    // store.commit('changeRechange', {code:false,msg:''})
 }
 // res.status==200&&store.commit('changeRechange', {code:false,msg:''})
  return res.data
},
err=>{
 if(err){
  let errResult=err.response
  top.window.require && addConsoleTxt(`网络错误:${err}`)
  if(errResult.status==408){
    //console.log('网络错误1111',err)
    store.commit('changeRechange', {code:true,msg:err.response.data.msg})
  }
  if(errResult.status==401){
    localStorage.setItem('token','')
    resizeTo(800,600)
    router.push('/login')
   }

    //console.log('网络错误',err)
   }
    return Promise.reject(err)
}
)

//get/post方法
const http = {
    get(url='',params={}){
      return new Promise((resolve, reject) => {
        _axios({
          url,
          params,
          method: 'GET'
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    post(url='',params={}){
       // console.log('params',params)
      return new Promise((resolve, reject) => {
        _axios({
          url,
          data:params,
          headers:{'Content-Type': 'application/json;charset=UTF-8'},
          method: 'POST'
        }).then(res => {
          resolve(res)
         
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
  
  
  export default http
