import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isStop: false,//是否暂停采集
    rechangeMsg: '',//408时候提示
    isRechange: true, //是否未充值
    info: {},//用户信息
    integralUser: {},//积分信息
    vision: '1.0', //版本号
    agentSetting: {},//代理商设置
    currentAgentSet:{},//代理商信息
    groupSet:{},//群组设置
  },
  mutations: {
    changeIntegral(state, data) {
      state.integralUser = data
    },
    changeCollect(state, data) {
      state.isStop = data
    },
    addInfo(state, data) {
      //console.log('data', data)
      //暂时去掉
      // state.isRechange=data.card_status==4||data.surplus==0?true:state.isRechange
      //console.log('isRechange', state.isRechange)
      state.info = data

    },
    changeRechange(state, data) {
      state.isRechange = data.code
      state.rechangeMsg = data.msg
    },
    //代理设置
    saveAgentSet(state, data) {
      state.agentSetting = data
    },
     //代理设置
     saveGroupSet(state, data) {
      
      state.groupSet = data
    },
    //当前代理商信息
    currentSet(state, data){
      state.currentAgentSet = data
    },
  },


})