import { Loading, Message } from 'element-ui';
import api from '../http/api';
import store from '../store/index'
import { getPath } from "../utils/win";
//校验手机号,邮箱
export function checkOut(type, data) {
    let regExp;
    if (type == 'phone') {
        regExp = new RegExp("^1[3578]\\d{9}$")
        return regExp.test(data) ? true : false
    }
    if (type == 'email') {
        regExp = /^([a-zA-Z0-9_-]{1,16})@([a-zA-Z0-9]{1,9})(\.[a-zA-Z0-9]{1,9}){0,3}(\.(?:com|net|org|edu|gov|mil|cn|us)){1,4}$/
        return regExp.test(data) ? true : false
    }
}

//封装loading加载
export function loadAction(lock, text, color) {
    return Loading.service({
        lock: lock ? lock : false,
        text: text ? text : '',
        spinner: 'el-icon-loading',
        background: color ? color : 'transparent'
    })
}
//获取当前日期
export function nowDataMethod(time) {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    month = addZero(month)
    day = addZero(day)
    let hour = addZero(date.getHours()); // 时
    let minutes = addZero(date.getMinutes()); // 分
    let seconds = addZero(date.getSeconds()) //秒
    let milliSeconds = addZero(date.getMilliseconds()); //毫秒
    return time ? (time='noraml'?`${year}${month}${day}${hour}${minutes}${seconds}`:`${year}年${month}月${day}日${hour}时${minutes}分${seconds}秒${time == 'ms' ? milliSeconds + '毫秒' : ''}` ): year + "-" + month + "-" + day;
}
function addZero(data) {
    return (data < 10) ? ("0" + data) : data
}
//获取用户信息
export function getInfo() {
    let that = this;
    api.getInfo().then(res => {
        //console.log("info", res);
        if (res.code == 200) {
            let save_address = localStorage.getItem('downAdress') ? localStorage.getItem('downAdress') : (info.response.save_address ? info.response.save_address : getPath())//没有远程的时候要获取安装目录地址
            localStorage.setItem('downAdress', save_address)
            store.commit("addInfo", {
                ...res.response,
                save_address: save_address
            });
        }
    })
}
//获取用户信息
export function getUser() {
    return new Promise((reslove, reject) => {
        api.getUserJf().then(res => {
            //console.log('获取用户积分', res)
            if (res.code == 200) {
                res.code == 200 && store.commit('changeIntegral', res.response)
                if (!res.response.is_available || !res.response.remain_num) {
                    reslove(true)
                    store.commit('changeRechange', { code: true, msg: res.response.msg && res.response.msg != null && res.response.msg != 'null' ? res.response.msg : '请购买积分卡，充值后使用' })
                } else {
                    store.commit('changeRechange', { code: false, msg: '' })
                }
                reslove(false)
            }

        })
    })

}
//获取当前时间前7天
export function getDays(day) {
    day = day ? day : 7
    let myDate = new Date(); //获取今天日期
    myDate.setDate(myDate.getDate() - day);
    var dateArray = [];
    var dateTemp;
    var flag = 1;
    for (var i = 0; i <= day; i++) {
        dateTemp = myDate.getFullYear() + '-' + (myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1) + "-" + (myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate());
        dateArray.unshift(dateTemp);
        myDate.setDate(myDate.getDate() + flag);
    }
    //console.log('dateArray---》',dateArray)
    return dateArray
}


//唯一标识符
export function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
//获取mac地址
export function getMacMethod() {
    return new Promise((reslove, reject) => {
        top.window.require ? window.getUnionId((id) => {
            reslove(id)
            // result.aunique_id=id
            // console.log('获取到的mac',id);
        }) : reslove('')
    })
}
//一键复制
export function copyMethod(wechatNumber) {
    if (wechatNumber) {
        var input = document.createElement("input");
        input.style.opacity = "0";
        input.value = wechatNumber;
        // 添加到页面上
        document.body.appendChild(input);
        // 选取输入框内容
        input.select();
        try {
            // 复制内容到剪贴板
            if (document.execCommand('copy')) {
                Message({
                    message: '复制成功',
                    type: 'success'
                })
            } else {
                Message({
                    message: '复制失败',
                    type: 'error'
                })
            }

        } catch (err) {
            Message({
                message: '复制失败',
                type: 'error'
            })
            console.error('无法复制微信号', err);
        } finally {
            // 移除输入框
            document.body.removeChild(input);
        }
    } else {
        Message({
            message: '暂无要复制的内容',
            type: 'info'
        })
    }
}


