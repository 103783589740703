import https from "./index.js";

let apiFun = {
  //注册
  register(params) {
    return https.post("/api/user/v1/account/register", params);
  },
  //登录
  login(params) {
    return https.post("/api/user/v1/account/login", params);
  },
  //获取用户信息
  getInfo(params) {
    return https.get("/api/user/v1/account/info", {});
  },
  //邮箱发送验证码
  sendEmail(params) {
    return https.post("/api/user/v1/account/email/send", params);
  },
  //找回密码
  findPwd(params) {
    return https.post("/api/user/v1/account/retrieve/password", params);
  },
  //获取登录人信息
  // getInfo() {
  //   return https.get("/api/user/v1/account/info", {});
  // },
  //领取积分卡
  getReceive(params) {
    return https.post("/api/user/v1/integralactivity/receive", params);
  },
  //历史充值明细
  getHistoryDetailes(params) {
    return https.get("/api/user/v1/integralactivity/history/detailes", params);
  },
  //获取配置
  getDownSetting(params) {
    return https.get("/api/user/v1/tool/get", params);
  },
  //使用手册
  getUseBook(params) {
    return https.get("/api/user/v1/tool/manual/get", params);
  },
  //提交需求
  getSubmit(params) {
    return https.post("/api/user/v1/user/demand/submit", params);
  },
  //获取配置
  getDispose(params) {
    return https.get("/api/user/v1/tool/get", params);
  },
  //使用明细
  getDetail(params) {
    return https.get("/api/user/v1/groups/groups/detail", params);
  },
  //获取用户剩余积分
  getRemainnum() {
    return https.get("/api/user/v1/account/user/remainnum", {});
  },
  //获取省
  getArea() {
    return https.get("/api/user/v1/region/get/province", {});
  },
  //获取群列表
  getGroup(params) {
    return https.get("/api/user/v1/groups/groups/list", params);
  },
  //清除已查看
  getDel() {
    return https.post("/api/user/v1/groups/groups/delhavetosee", {});
  },
  //清除已下载
  getDelDown() {
    return https.post("/api/user/v1/groups/groups/delhavedownload", {});
  },
  //设置下载地址
  settingAddress(params) {
    return https.post("/api/user/v1/user/info/saveaddress", params);
  },
  //获取下载所需积分
  getRequirintegral(params) {
    return https.get("/api/user/v1/groups/get/requirintegral", params);
  },
  //查看群组
  getToview(params) {
    return https.post("/api/user/v1/groups/groups/toview", params);
  },
  //下载
  downList(params) {
    return https.post("/api/user/v1/groups/group/next", params);
  },
  //下载后群组操作
  downAfter(params) {
    return https.post("/api/user/v1/groups/groups/after/download", params);
  },
  //勾选数组所需积分
  checkNeed(params) {
    return https.post("/api/user/v1/groups/secifi/integral", params);
  },
  //勾选数组获取下一条
  checkOther(params) {
    return https.post("/api/user/v1/groups/secifi/groupnext", params);
  },
  //实时获取用户积分
  getUserJf() {
    return https.get("/api/user/v1/groups/get/integral", {});
  },
  //充值前调
  getIsRechange() {
    return https.get("/api/user/v1/integralactivity/check/receive", {});
  },
  //获取提现账户下拉
  getAccountDrop(params) {
    return https.get("/api/user/v1/withdrawal/account/drop", params);
  },
  //申请提现
  applyAccount(params) {
    return https.post("/api/user/v1/withdrawal/create", params);
  },
  //获取提现记录
  getWithdrawalList(params) {
    return https.get("/api/user/v1/withdrawal/getlist", params);
  },
  //获取收益明细
  getWithdrawalDetail(params) {
    return https.get("/api/user/v1/withdrawal/groups/detail", params);
  },
  //获取用户佣金余额
  getAccountCommission(params) {
    return https.get("/api/user/v1/account/commission", params);
  },
  //获取账户信息列表
  getBankUserList(params) {
    return https.get("/api/user/v1/withdrawal/account/getlist", params);
  },
  // 获取渠道后台权限(？)是这个接口？
  getBackStage() {
    return https.get("/api/user/v1/withdrawal/account/getbyid", {});
  },
  //添加提现账户
  addBankUser(params) {
    return https.post("/api/user/v1/withdrawal/account/add", params);
  },
  //根据id修改提现账户
  updateBankUser(params) {
    return https.post("/api/user/v1/withdrawal/account/editbyid", params);
  },
  //获取提现类型
  getBankType() {
    return https.get("/api/user/v1/withdrawal/bank/type/drop", {});
  },
  //删除
  deleteBanlUser(params) {
    return https.post("/api/user/v1/withdrawal/account/del", params);
  },
  // 今日上传
  getTodayUpload(params) {
    return https.get("/api/user/v1/channel/groups/upload/today", params);
  },
  //检查更新
  getUpdate(params) {
    return https.get("/api/user/v1/tool/get", params);
  },
  //获取最大下载量和相应的积分
  getMaxDown(params) {
    return https.get("/api/user/V1/downloadtask/get/download/points", params);
  },
  //获取用户待下载任务
  getUserWait(params) {
    return https.get("/api/user/V1/downloadtask/get/user/wait", params);
  },
  //创建下载任务
  createDown(params) {
    return https.post("/api/user/V1/downloadtask/create", params);
  },
  //下载txt
  // downTxt(params) {
  //   return https.post("/api/user/V1/downloadtask/download", params);
  // },
 //下载成功
  downSuccess(params) {
    return https.post("/api/user/V1/downloadtask/download/success", params);
  },
  //下载失败
  downError(params) {
    return https.post("/api/user/V1/downloadtask/download/fail", params);
  },
  logout(params) {
    return https.post("/api/user/v1/account/logout", params);
  },
  //监听心跳
  listenJump(){
    return https.post("/api/user/v1/user/operation", {});
  },
  //下载屏蔽关键词
  getKeyword(params){
    return https.get("/api/user/v1/user/keyword/get", params);
  },
  //设置屏蔽关键词
  setKeyword(params){
    return https.post("/api/user/v1/user/keyword/set", params);
  },
   //绑定代理商邀请码
   bindInviteCode(params){
    return https.post("/api/user/v1/user/invite-code/set", params);
   },
   //获取我的代理商联系方式
   getMyAgentCode(){
    return https.get("/api/user/v1/user/my/agent/contact", {});
   },
   //获取代理商邀请用户列表
    getInviteList(params){
      return https.get("/api/user/v1/agent/invite/list", params);
    },
    //获取当前登录的代理商信息
    getAgentContact(){
      return https.get("/api/user/v1/agent/info", {}); 
    },
    //上传图片
    uploadImg(params){
      return https.post("/api/admin/v1/systemsetting/upload", params);
    },
    //设置联系方式
    setContact(params){
      return https.post("/api/user/v1/agent/contact/set", params);
    },
    //修改密码
    updatePwd(params){
      return https.post("/api/user/v1/account/change/password", params);
    },
    //修改邮箱
    updateEmail(params){
      return https.post("/api/user/v1/account/change/email", params);
    },
    //下载统计
    getDownStatistics(params){
      return https.get("/api/user/v1/groups/down/log/statistics", params);
    },
    downUserGroup(params){
      return https.get("/api/user/v1/groups/user-group/next", params);
    },
};

export default apiFun;
