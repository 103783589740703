<template>
  <div id="app">
   <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style lang="less">
@import '../src/assets/styles/base.less';
#app{
	width:100%;
	height:100vh;
	position:relative;
	overflow: hidden;
}
/* 分页 */

	 .el-pagination {
		position: absolute;
		left: 0;
		bottom: 0;
	   padding:0 !important;
		button {
		  font-size: 12px;
		  height: 25px !important;
		  line-height: 25px !important;
		  margin: 0 2px !important;
		min-width: 25px;
		}
		.btn-prev{
		  margin-left:0;
		}
	  }
	  
 .el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #0B41D7 !important;
	  
	  }
	  
	 .el-pagination.is-background .el-pager li {
		margin: 0 2px !important;
		min-width: 25px;
		font-size: 12px;
		height: 25px;
		line-height: 25px;
	  }
	 
	  .indexLoad   .el-loading-spinner{

    top: 50% !important;
    margin-left: 0 !important;
   
}
[v-cloak]{
    display: none;
  }
</style>
