import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes=[
    {
        path:'/',
        name:'Login',
      component:()=>import('../views/login.vue')  //按需引用路由
    },
    {
    path:'/login',
    name:'Login',
    // meta:{
    //     requireAuth:true
    // },
   component:()=>import('../views/login.vue')  //按需引用路由
},
{
    path:'/index',
    name:'index',
    // meta:{
    //     requireAuth:true
    // },
    component:()=>import('../views/index.vue')  
},
]
const router=new VueRouter({
    mode:'hash',
    routes
})

export default router;